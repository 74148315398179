import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import SVG from "./svg"

const SocialStyled = styled.div`
  width: 180px;
  margin: 30px auto;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    font-weight: bold;
    color: ${(p) => p.theme.LightBlue};
    font-size: 14px;
    display: inline-block;
    padding: 0 5px;
  }

  .socialSVG {
    height: 32px;
    width: 32px;
  }
`

const Social = ({ social }) => {
  const theme = useContext(ThemeContext)
  const socials = {
    twitter: "TW",
    instagram: "IG",
    facebook: "FB",
    pinterest: "PI",
  }
  let iconName
  return (
    <SocialStyled>
      {social.map((icon, i) => {
        Object.keys(socials).map((k) => {
          const match = icon.link.match(new RegExp(k))
          if (match) iconName = socials[k]
        })
        return (
          <a
            key={i}
            href={icon.link}
            target="_blank"
            aria-label={icon.alt_text}
            rel="noopener noreferrer"
          >
            <SVG name={iconName} color={theme.Blue} className="socialSVG" />
          </a>
        )
      })}
    </SocialStyled>
  )
}

export default Social
