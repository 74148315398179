import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Layout from "../components/layout"
import Center from "../components/center"
import ResponsiveSpacing from "../components/responsive-spacing"
import BottomColorNav from "../components/bottom-color-nav"
import QuoteBanner from "../components/quote-banner"
import Feature from "../components/feature"
import CompactFeature from "../components/compact-feature"
import EventsFeature from "../components/about-us/events-feature"
import Heading from "../components/heading"
import HeroArea from "../components/scoop-shop/hero"
import StoreMenu from "../components/scoop-shop/store-menu"
import SEO from "../components/seo"
import { Row, Col } from "../components/rows"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "../components/svg"
import Garnish from "../components/garnish"
import OpeningBanner from "../components/opening-banner"
import Social from "../components/social"

const ScoopShopStyled = styled.div`
  position: relative;
  .specialEvent {
    position: relative;
  }
  .deliveryPartners {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    a {
      transform: scale(0.8);
      width: 25%;

      .img {
        display: block !important;
      }
      .imgHover {
        display: none !important;
      }
    }

    @media (min-width: 769px) {
      a:hover {
        color: ${(p) => p.theme.Blue};
        transition: transform 250ms, color 200ms;
        transform: scale(1);
        z-index: 2;
        color: ${(p) => p.theme.Blue};

        .img {
          display: none !important;
        }
        .imgHover {
          display: block !important;
        }
      }
    }

    @media (max-width: 768px) {
      a {
        width: auto;
        display: block;
        text-align: center;
        margin: 20px auto;
      }
    }
  }
`

const ScoopShop = ({ fields, flavors, social }) => {
  const {
    opening,
    events,
    features,
    delivery,
    menu,
    bottom_feature,
    call_to_action,
    montage_photo,
    mobile_montage_photo,
    quote,
  } = fields
  const theme = useContext(ThemeContext)

  const sources = mobile_montage_photo
    ? [
        {
          media: "(min-width: 769px)",
          ...montage_photo.localFile.childImageSharp.fluid,
        },
        {
          media: "(max-width: 768px)",
          ...mobile_montage_photo.localFile.childImageSharp.fluid,
        },
      ]
    : montage_photo
    ? [
        {
          ...mobile_montage_photo.localFile.childImageSharp.fluid,
        },
      ]
    : []

  return (
    <ScoopShopStyled>
      <HeroArea fields={fields}>
        <h5>Location</h5>
        <p>{fields.hero_area.location}</p>
        <h5>Phone</h5>
        <p>{fields.hero_area.phone}</p>
        <h5>Hours</h5>
        <p>{fields.hero_area.hours}</p>
      </HeroArea>
      <OpeningBanner>
        <div className="inner">
          <ResponsiveSpacing>
            <h2 tabIndex="0">{opening.headline}</h2>
            <p>{opening.statement}</p>
          </ResponsiveSpacing>
        </div>
      </OpeningBanner>
      {events.hide_all ? (
        ""
      ) : (
        <EventsFeature {...fields.events} align="left" margin="150px auto" />
      )}
      <ResponsiveSpacing>
        <Heading margin="50px 0 0px 0">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: call_to_action,
            }}
          />
        </Heading>
      </ResponsiveSpacing>

      <Social social={social} />

      <Img fluid={sources} />
      <ResponsiveSpacing>
        <StoreMenu {...menu} flavors={flavors} />
        <Heading margin="150px 0 0 0">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: delivery.headline,
            }}
          />
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: delivery.sub_heading,
            }}
          />
          <Center margin="20px auto">
            <SVG name="SWave" className="swave" color={delivery.wave_color} />
          </Center>
        </Heading>
        <div className="deliveryPartners">
          {delivery.logos.map((l, i) => {
            return (
              <a
                href={l.link_out}
                target="_blank"
                rel="noopener noreferrer"
                key={i}
              >
                <Img
                  alt={l.alt_text}
                  className="img"
                  fixed={l.image.localFile.childImageSharp.fixed}
                />
                <Img
                  alt={l.alt_text}
                  className="imgHover"
                  loading="eager"
                  fixed={l.hover_image.localFile.childImageSharp.fixed}
                />
              </a>
            )
          })}
        </div>
      </ResponsiveSpacing>
      <ResponsiveSpacing>
        <Row width="900px" margin="150px auto">
          <Col>
            <CompactFeature {...features[0]} align={"left"} />
          </Col>
          <Col>
            <CompactFeature {...features[1]} align={"right"} />
          </Col>
        </Row>
      </ResponsiveSpacing>
      <QuoteBanner
        quote={quote.quote_text}
        quoted={quote.quoted}
        text_color={theme.DarkBlue}
        color={theme.LightBlue}
      >
        <Garnish
          garnish={quote.garnish}
          pSpeed={-0.4}
          top={-1800}
          width={250}
          rotation={0}
          rSpeed={0}
          pos={"left"}
        />
      </QuoteBanner>
      <ResponsiveSpacing>
        <Feature {...bottom_feature} align={"right"} margin="150px auto" />
      </ResponsiveSpacing>
      <BottomColorNav />
    </ScoopShopStyled>
  )
}

const ScoopShopPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: allWordpressPage(filter: { slug: { eq: "scoop-shop-page" } }) {
        edges {
          node {
            acf {
              hero_area {
                top_line
                headline
                top_line_color
                headline_color
                wave_color
                location
                phone
                hours
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                mobile_background {
                  source_url
                }
              }
              opening {
                headline
                statement
              }
              events {
                hide_all
                headline
                sub_heading
                summary
                wave_color
                button_link
                button_label
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                events {
                  hide
                  headline
                  information
                }
                garnish {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 250, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              call_to_action
              montage_photo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              mobile_montage_photo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              menu {
                headline
                wave_color
                seasonal_headline
                seasonal_summary
                seasonal_flavors {
                  name
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 400, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                favorites_headline
                favorites_summary
                favorite_flavors {
                  wordpress_id
                }
                bottom_heading
                left_side {
                  name
                  ingredients
                }
                right_side {
                  name
                  ingredients
                }
                byo {
                  heading
                  instructions {
                    text
                  }
                  toppings {
                    name
                  }
                }
                garnishes {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 178, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              delivery {
                headline
                sub_heading
                wave_color
                logos {
                  link_out
                  alt_text
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 200, quality: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  hover_image {
                    localFile {
                      childImageSharp {
                        fixed(width: 200, quality: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
              features {
                headline
                sub_heading
                summary
                wave_color
                button_label
                button_link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              quote {
                quote_text
                quoted
                garnish {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 250, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              bottom_feature {
                headline
                sub_heading
                summary
                wave_color
                button_label
                button_link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      flavors: allWordpressWpFlavors {
        edges {
          node {
            wordpress_id
            acf {
              flavor {
                name
                inside_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      social: allWordpressPage(filter: { slug: { eq: "shared-layout" } }) {
        edges {
          node {
            acf {
              social {
                alt_text
                icon {
                  source_url
                }
                link
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.page.edges[0].node.acf
  const social = data.social.edges[0].node.acf.social
  const flavors = data.flavors.edges.map((e) => e.node)

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ScoopShop fields={fields} flavors={flavors} social={social} />
    </Layout>
  )
}

export default ScoopShopPage
