import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "../svg"
import Button from "../button"
import Garnish from "../garnish"

const InfoBox = styled.div`
  margin: 0 ${(p) => (p.align === "right" ? "70px 0 0" : "0 0 70px")};
  max-width: 450px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0px auto;
    padding: 0px 8px;
    width: auto;
  }

  h2 {
    color: ${(props) => props.theme.DarkBlue};
    margin: 10px 0;
    font-size: 7.2em;
    line-height: 60px;
    font-family: "interstate-compressed";
    font-weight: 800;
    line-height: 0.9em;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  h3 {
    color: ${(props) => props.theme.Blue};
    margin: 10px 0;
    font-size: 2em;
    font-family: "Montserrat";
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  p {
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 10px 0 20px 0;
    color: ${(props) => props.theme.DarkBlue};
    a {
      color: ${(p) => p.theme.DarkBlue};
    }
  }
`

const ImageStyled = styled.div`
  position: relative;

  @media (max-width: 768px) {
    margin: 0 auto 10px auto;
  }

  .img {
    max-width: 450px;
    max-height: 450px;
  }

  .gatsby-image-wrapper {
    overflow: hidden;
    max-width: 450px;
    max-height: 450px;
    width: 450px;
    @media (max-width: 768px) {
      margin: 0 5px;
      width: 94vw;
    }
  }
`

const EventsFeatureStyled = styled.div`
  max-width: 980px;
  margin: ${(p) => p.margin || "0px auto"};
  z-index: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  padding: 0px 4px;
  flex-direction: ${(p) => (p.align === "left" ? "row-reverse" : "row")};

  .swave {
    position: absolute;
    z-index: 2;
    top: 100px;
    right: ${(p) => (p.align === "left" ? "auto" : "-80px")};
    left: ${(p) => (p.align === "left" ? "-80px" : "auto")};
    margin: 20px 0 0 0;
    transform: scaleX(${(p) => (p.align === "left" ? "-1" : "1")});
  }

  .stuck {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 50px 0;
  }

  .eventList {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    .event {
      width: 50%;
    }
    h4.eventHeadline {
      margin: 0 0 10px 0;
      padding: 0;
      font-family: "interstate-compressed";
      font-size: 2.4em;
      line-height: 0.9em;
      color: ${(p) => p.theme.DarkBlue};
      a {
        color: ${(p) => p.theme.DarkBlue};
      }
    }
    h5.eventInfo {
      margin: 0;
      padding: 0;
      color: ${(p) => p.theme.Blue};
      font-size: 1.4em;
      a {
        color: ${(p) => p.theme.Blue};
      }
    }
  }
`

const EventsFeature = (props) => {
  const {
    align,
    headline,
    margin,
    sub_heading,
    events,
    summary,
    image,
    button_label,
    wave_color,
    button_link,
    eagerLoadImage,
    garnish,
  } = props
  let img
  const im = image
  if (im && im.localFile)
    img = (
      <Img
        fluid={im.localFile.childImageSharp.fluid}
        alt={headline}
        loading={eagerLoadImage ? "eager" : "lazy"}
      />
    )
  if (im && typeof im === "string")
    img = <img alt={headline} className="img" src={image} />
  return (
    <EventsFeatureStyled align={align} margin={margin}>
      <Garnish
        garnish={garnish}
        pSpeed={-0.4}
        top={-370}
        width={230}
        rotation={0}
        rSpeed={0}
        pos={"right"}
      />
      <InfoBox align={align}>
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
        {sub_heading ? (
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: sub_heading,
            }}
          />
        ) : (
          ""
        )}
        {summary ? (
          <p
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: summary,
            }}
          />
        ) : (
          ""
        )}
        {button_label ? <Button link={button_link}>{button_label}</Button> : ""}
        <div className="eventList">
          {events.map((ev, i) => {
            if (ev.hide) return
            const { headline, information } = ev
            return (
              <div className="event" key={i}>
                <h4
                  tabIndex="0"
                  className="eventHeadline"
                  dangerouslySetInnerHTML={{
                    __html: headline,
                  }}
                />
                <h5
                  tabIndex="0"
                  className="eventInfo"
                  dangerouslySetInnerHTML={{
                    __html: information,
                  }}
                />
              </div>
            )
          })}
        </div>
      </InfoBox>
      <ImageStyled>
        <SVG name="SWave" className="swave" color={wave_color} /> {img}
      </ImageStyled>
    </EventsFeatureStyled>
  )
}

export default EventsFeature
