import React, { useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "../svg"
import HeroRow from "../hero-row"

const HeroAreaStyled = styled.div`
  position: relative;
  height: 690px;
  overflow: hidden;
  margin: 93px auto 0 auto;
  max-width: 2560px;

  .gif {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 690px;
    transition: opacity: 1s;
    transition-delay: 1s;
    opacity: 0;

    img {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .background {
    display: flex;
    justify-conent: center;
    align-items: flex-start;
    overflow: hidden;
    max-width: 2560px;
    margin: 0 auto;
    top: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 690px;
  }

  .topline {
    color: ${(p) => p.fields.hero_area.top_line_color || p.theme.DarkBlue};
  }

  .headline {
    color: ${(p) => p.fields.hero_area.headline_color || p.theme.White};
  }

  h5 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.White};
    background-color: ${(p) => p.theme.DarkBlue};
    font-size: 2.4em;
    text-transform: uppercase;
    padding: 2px 6px;
    margin: 20px 0 5px 0;
    letter-spacing: 2px;
    display: inline-block;
  }

  p {
    color: ${(p) => p.theme.DarkBlue};
    font-weight: bold;
    font-size: 1.4em;
    margin: 0;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    min-height: 400px;
    width: 100%;
    padding: 0;

    .gif {
      display: none;
    }

    .wave {
      position: relative;
    }

    .topline {
      font-size: 1.6em;
    }

    .headline {
      font-size: 5em;
    }

    h5 {
      font-size: 1em;
    }

    p {
      font-size: 0.7em;
      max-width: 160px;
    }
  }
`

const HeroArea = ({ fields, children }) => {
  useEffect(() => {
    document.querySelector(".gif").style.opacity = "1"
  }, [])
  const sources = fields.hero_area.mobile_background
    ? [
        {
          media: "(min-width: 769px)",
          ...fields.hero_area.background.localFile.childImageSharp.fluid,
        },
        {
          media: "(max-width: 768px)",
          src: fields.hero_area.mobile_background.source_url,
        },
      ]
    : fields.hero_area.background
    ? [
        {
          ...fields.hero_area.background.localFile.childImageSharp.fluid,
        },
      ]
    : []
  return (
    <HeroAreaStyled fields={fields}>
      <div className="background">
        <Img
          objectFit="cover"
          objectPosition="50% 100%"
          className="img"
          alt={fields.headline}
          fluid={sources}
        />
        <div className="gif">
          <img
            role="decorative"
            alt=""
            src="/scoopshopsign-animation-inset.gif"
          />
        </div>
      </div>
      <HeroRow>
        <h1 className="heroH1">
          <div
            className="topline"
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.top_line,
            }}
          />
          <div
            className="headline"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.headline,
            }}
            tabIndex="0"
          />
          <SVG name="SWave" color={"White"} />
        </h1>
        {children}
      </HeroRow>
    </HeroAreaStyled>
  )
}

export default HeroArea
