import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import List from "../../components/list"
import Center from "../../components/center"
import Garnish from "../../components/garnish"
import SVG from "../../components/svg"
import { Row, Col } from "../../components/rows"
import styled from "styled-components"

const StoreMenuStyled = styled.div`
  margin: 150px auto;
  max-width: 1400px;
  border: 2px solid ${(p) => p.theme.Blue};
  padding: 40px;
  position: relative;

  @media (max-width: 1268px) {
    margin: 100px 8px 0;
  }

  @media (max-width: 768px) {
    padding: 8px 40px;
  }

  h2 {
    font-family: "interstate-compressed";
    font-size: 6em;
    color: ${(p) => p.theme.DarkBlue};
    background-color: ${(p) => p.theme.White};
    max-width: 700px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 1em;
    padding: 0 10px;
    text-align: center;
    margin: 100px auto 20px auto;
    text-transform: uppercase;
  }

  h2.onLine {
    margin: -70px auto 20px auto;
  }

  h3 {
    font-size: 2.4em;
    margin: 30px 0 0 0;
    text-align: center;
    color: ${(p) => p.theme.DarkBlue};
  }

  p {
    font-size: 1.4em;
    padding: 10px 0 30px 0;
    margin: 0;
    text-align: center;
    color: ${(p) => p.theme.DarkBlue};
  }

  .byo {
    border: 2px solid ${(p) => p.theme.Blue};
    padding: 30px 40px;
    margin: 20px 0;
    font-weight: bold;

    @media (max-width: 1168px) {
      padding: 30px 20px;
    }
  }

  .byo h4 {
    font-size: 2.4em;
    margin: 0;
    text-align: center;
    letter-spacing: 2px;
    color: ${(p) => p.theme.DarkBlue};
  }

  .items {
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    .menuItem:last-child {
    }
  }

  .menuItem h5 {
    font-family: "interstate-compressed";
    font-size: 2.4em;
    margin: 0;
    text-align: center;
    color: ${(p) => p.theme.Blue};
  }

  .menuItem p {
    font-size: 1.4em;
    margin: 0;
    color: ${(p) => p.theme.DarkBlue};
  }

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
  }

  .heading {
    width: 100%;
  }

  .flavorsRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .flavor {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0 10px;
    }
  }

  .flavorImage {
    max-width: 200px;
    width: 100%;
    height: 80px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }

  .flavorName {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.Blue};
    font-size: 2.4em;
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .toppings {
    text-align: center;
    color: ${(p) => p.theme.DarkBlue};
    display: inline-block;
    line-height: 30px;
    margin: 8px 0px;
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
  }
`

const StoreMenu = ({
  headline,
  wave_color,
  seasonal_headline,
  seasonal_summary,
  seasonal_flavors,
  favorites_headline,
  favorites_summary,
  favorite_flavors,
  bottom_heading,
  left_side,
  right_side,
  byo,
  garnishes,
  flavors,
}) => {
  const sfIds = favorite_flavors.map((f) => f.wordpress_id)
  let choosenFlavors = flavors.filter((af) => sfIds.includes(af.wordpress_id))
  return (
    <StoreMenuStyled>
      <Garnish
        garnish={garnishes[0]}
        pSpeed={-0.4}
        top={-750}
        rotation={170}
        rSpeed={-0.1}
        width={150}
        pos={"left"}
        style={{
          marginLeft: "0px",
        }}
      />
      <Garnish
        garnish={garnishes[1]}
        pSpeed={-0.4}
        top={-500}
        rotation={40}
        rSpeed={-0.04}
        width={150}
        pos={"right"}
      />
      <Garnish
        garnish={garnishes[2]}
        pSpeed={-0.4}
        top={0}
        rotation={-90}
        rSpeed={-0.04}
        width={150}
        pos={"left"}
      />
      <h2 className="onLine">{headline}</h2>
      <Center>
        <SVG name="SWave" className="swave" color={wave_color} />
      </Center>
      <div className="section">
        <div className="heading">
          <h3>{seasonal_headline}</h3>
          <p>{seasonal_summary}</p>
        </div>
        <div className="flavorsRow">
          {seasonal_flavors.map((s, i) => {
            const { image, name } = s
            if (!image || !name) return ""
            return (
              <div className="flavor" key={i}>
                <div className="flavorImage">
                  {image ? (
                    <Image
                      className="img"
                      fluid={image.localFile.childImageSharp.fluid}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="flavorName">{name}</div>
              </div>
            )
          })}
        </div>
        <div className="heading">
          <h3>{favorites_headline}</h3>
          <p>{favorites_summary}</p>
        </div>
        {choosenFlavors.map((f, index) => {
          const { name } = f.acf.flavor
          return (
            <div className="flavor" key={index}>
              <div className="flavorName">{name}</div>
            </div>
          )
        })}
      </div>
      <h2>{bottom_heading}</h2>
      <Center>
        <SVG name="SWave" className="swave" color={wave_color} />
      </Center>
      <Row columns="1fr 1fr 1fr" mobileMargin="0px">
        <Col className="items">
          {left_side.map((ls, i) => {
            return (
              <div className="menuItem" key={i}>
                <h5>{ls.name}</h5>
                <p>{ls.ingredients}</p>
              </div>
            )
          })}
        </Col>
        <Col>
          <div className="byo">
            <h4>{byo.heading}</h4>
            <List>
              {byo.instructions.map((item, i) => {
                const color = ["green", "orange", "red", "blue"][
                  Math.floor(i % 4)
                ]
                const classes = `bullet ${color}`
                return (
                  <li key={i}>
                    <span className={classes}>{i + 1}</span>
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                  </li>
                )
              })}
            </List>
            <div className="toppings">
              {byo.toppings.map((t) => t.name).join("  •  ")}
            </div>
          </div>
        </Col>
        <Col className="items">
          {right_side.map((rs, i) => {
            return (
              <div className="menuItem" key={i}>
                <h5>{rs.name}</h5>
                <p>{rs.ingredients}</p>
              </div>
            )
          })}
        </Col>
      </Row>
    </StoreMenuStyled>
  )
}

export default StoreMenu
