import React from "react"
import styled from "styled-components"
import RoughEdge from "./rough-edge"
import SVG from "./svg"

const QuoteBannerStyled = styled.div `
  position: relative;
  background-color: ${p => p.color};
  font-weight: bold;
  letter-spacing: 1.2px;

  > .inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column;
    padding: 40px 0;
    min-height: 300px;
  }

  .svg,
  .text {
    margin: 0px 0;
  }

  .svg {
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }
  }

  .quoted {
    font-size: 1.7em;
    text-align: center;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${p => p.text_color || p.color};
  }

  .text {
    font-family: "interstate-compressed";
    font-size: 2.4em;
    text-align: ${p => p.text_align || "center"};
    text-transform: uppercase;
    color: ${p => p.text_color || p.color};
    max-width: 600px;

    @media (max-width: 768px) {
      width: 100vw;
      padding: 0 40px;
    }
  }
`

const QuoteBanner = ({
  text_color,
  text_align,
  quote,
  quoted,
  children,
  color,
  opacity
}) => {
  return (<QuoteBannerStyled text_color={text_color} text_align={text_align} color={color} opacity={opacity}>
    <RoughEdge className="top" color={color}/>
    <div className="inner">
      <SVG name="LWave" className="svg" color={"White"}/>
      <div className="text" dangerouslySetInnerHTML={{
          __html: quote
        }}/>
      <SVG name="LWave" className="svg" color={"White"}/>
      <h6 className="quoted" dangerouslySetInnerHTML={{
          __html: quoted
        }}/>
    </div>
    {children}
    <RoughEdge className="bottom" color={color}/>
  </QuoteBannerStyled>)
}

export default QuoteBanner
